//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TournamentInfo from '@/components/Tournaments/TournamentInfo.vue';
import Timer from '@/components/Tournaments/TournamentTimer.vue';
export default {
  // name: 'ComponentName',
  props: {
    tournament: Object
  },
  components: {
    'app-info': TournamentInfo,
    'app-timer': Timer
  },
  data: function data() {
    return {};
  }
};